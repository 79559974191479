export const state = () => ({
  page: {},
  layout: 'default',
  mobile: false
})

export const mutations = {
  set(state, page) {
    state.page = page

    if (page.layout) {
      state.layout = page.layout
    }
  },
  mobile(state, isMobile) {
    state.mobile = isMobile
  }
}
