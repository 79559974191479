//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import config from '~/content/data/config.json'
export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      header: config.header
    }
  },
  computed: {
    ...mapState({
      page: (state) => state.pages.page
    })
  },
  methods: {
    menuClick(menu, event) {
      if (menu.menu.length > 0) {
        event.preventDefault()
      }
    },
    isActive(path) {
      if (!path) return false

      path = path.trimEnd('/')

      if (this.$route.path.includes(path)) return true

      return this.$route.path === path
    }
  }
}
