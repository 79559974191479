import GlobalConfig from '~/content/data/config.json'
export default function (page, app) {
  const head = {
    title: page.title,
    meta: [],
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'LocalBusiness',
          image: ['https://ussc.com.ph/uploads/ussc-socials.jpg'],
          '@id': 'https://ussc.com.ph',
          url: 'https://ussc.com.ph',
          name: 'USSC',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'USSC Building, No. 711 EDSA cor. New York St.',
            addressLocality: 'Cubao',
            addressRegion: 'Quezon City',
            postalCode: '1109',
            addressCountry: 'Philippines'
          },
          description: GlobalConfig.description,
          telephone: '(63) 2 8928-8772'
        }
      }
    ]
  }

  const options = {
    url: 'https://ussc.com.ph',
    title: page.title || GlobalConfig.title,
    site_name: 'USSC',
    description: page.description || GlobalConfig.description,
    img: 'https://ussc.com.ph/uploads/ussc-socials.jpg',
    locale: 'en_PH',
    themeColor: '#6a1390'
  }
  let keywords = GlobalConfig.keywords

  if (page.keywords && page.keywords.length > 0) keywords = page.keywords

  const metaTags = [
    { name: 'description', content: options.description },
    { name: 'keywords', content: keywords.join(', ') },
    // Global
    { name: 'author', content: options.url },
    { name: 'publisher', content: options.url },
    { name: 'apple-mobile-web-app-title', content: options.title },
    { name: 'theme-color', content: options.themeColor },

    // Facebook & LinkedIn
    { property: 'og:title', content: options.title },
    { property: 'og:description', content: options.description },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: options.url },
    { property: 'og:image', content: options.img },
    { property: 'og:locale', content: options.locale },
    { property: 'og:site_name', content: options.site_name }
  ]

  metaTags.forEach((tag) => {
    if (tag.content !== undefined && tag.content !== null) {
      if (tag.hasOwnProperty('name')) {
        head.meta.push({
          hid: tag.name,
          name: tag.name,
          content: tag.content
        })
      } else if (tag.hasOwnProperty('property')) {
        head.meta.push({
          hid: tag.property,
          property: tag.property,
          content: tag.content
        })
      }
    }
  })

  return head
}
