//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'PageFooter',
  data() {
    return {
      logo: 'uploads/ussclogo-footer.svg'
    }
  },
  computed: {
    ...mapState({
      page: (state) => state.pages.page
    })
  },
  methods: {
    srcset(img) {
      const img2x = img.replace(/.(jpg|png|jpeg)$/, function (x) {
        return '@2x' + x
      })
      return img + ' 1x, ' + img2x + ' 2x'
    }
  }
}
