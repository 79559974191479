//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import socialize from '~/components/socials'

import { mapState } from 'vuex'
import PageHeader from './partials/PageHeader.vue'
import PageFooter from './partials/PageFooter.vue'
import PageHeader_v2 from './partials/PageHeader_v2.vue'
import PageFooter_v2 from './partials/PageFooter_v2.vue'

export default {
  head() {
    return socialize(this.page)
  },
  scrollToTop: true,
  data() {
    return {
      sizeTimeout: null
    }
  },
  components: {
    PageHeader,
    PageFooter,
    PageHeader_v2,
    PageFooter_v2
  },
  computed: {
    ...mapState({
      page: (state) => state.pages.page,
      mobile: (state) => state.pages.mobile
    }),
    layout() {
      return this.page.layout || 'default'
    }
  },
  methods: {
    onResize() {
      this.checkSize()
    },
    checkSize() {
      const self = this
      this.$nextTick(function () {
        clearTimeout(self.sizeTimeout)
        if (typeof window !== 'undefined') {
          self.$store.commit('pages/mobile', parseFloat(window.getComputedStyle(document.querySelector('body')).width) < 992)
        } else {
          self.sizeTimeout = setTimeout(function () {
            self.checkSize()
          }, 100)
        }
      })
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.onResize()
    })
    window.addEventListener('resize', this.onResize)
  },
  created() {
    this.checkSize()
  }
}
