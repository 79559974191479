//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'PageFooter_v2',
  data() {
    return {
      logo: 'uploads/ussclogo-footer.svg'
    }
  },
  computed: {
    ...mapState({
      page: (state) => state.pages.page
    })
  },
}
