// function initFreshChat() {
//   window.fcWidget.init({
//     token: 'dda4fead-7bd3-4ad5-9874-7d5e5468ae39',
//     host: 'https://wchat.freshchat.com'
//   })
// }
// function initialize(i, t) {
//   var e
//   i.getElementById(t)
//     ? initFreshChat()
//     : (((e = i.createElement('script')).id = t), (e.async = !0), (e.src = 'https://wchat.freshchat.com/js/widget.js'), (e.onload = initFreshChat), i.head.appendChild(e))
// }
// function initiateCall() {
//   initialize(document, 'Freshdesk Messaging-js-sdk')
// }
// window.addEventListener ? window.addEventListener('load', initiateCall, !1) : window.attachEvent('load', initiateCall, !1)

// OLD
  // window.ProtoSettings = {
  //   gwId: 'gw_ntYMgsoIxePvdHIo4tFFuFI',
  //   env: '',
  //   onLoad: function() { Proto.show(); }
  // };

  // var d=document,s=d.createElement("script");
  // s.type="text/javascript",s.async=true,s.src="https://app.proto.cx/webchat/client.js";
  // var t=d.getElementsByTagName("script")[0];
  // t.parentNode.insertBefore(s,t);
  // s.addEventListener("load", function() {
  //   var p = window.ProtoSettings; Proto.init(p, p.onLoad);
  // });


//NEW
  window.protoSettings = {
    id: '01J19X903BW238NXTA2JYXBNEE'
  };

  var prs = document.createElement('script');
  prs.src = 'https://embed.proto.cx/index.umd.js';
  prs.type = 'text/javascript';
  prs.async = true;
  prs.onload = function () {
    window.proto.init(window.protoSettings);
  };
  document.head.appendChild(prs);